import React from "react";
import {Form} from "react-bootstrap";
import { TextField } from "@mui/material";
import '../css/poi.css';
import MenuItem from '@mui/material/MenuItem';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const isMilitaryForm = (handleValue) => {
    return (
    <Form.Group>
        <Form.Label>Military or Civilian?</Form.Label>
            <TextField
                className="mb-2"
                    minRows={3}
                    style={{width:"100%"}}
                    onChange={handleValue}
                    name="value"
                    select
                    multiline
                    required
                    >
                    <MenuItem value="0">Civilian</MenuItem>
                    <MenuItem value="1">Military</MenuItem>
            </TextField>
        </Form.Group>
    )
}

export const textForm = (titleText = "", placeText = "", minHeight = 1) => {
    return (
        <Form.Group>
            <Form.Label>{titleText}</Form.Label>
            <TextField
                className="mb-2"
                minRows={minHeight}
                style={{ width: "100%" }}
                name="value"
                placeholder={placeText}
                multiline
                required
            />
        </Form.Group>
    )
}


export const statusForm = (handleValue) => {
    return (
        <Form.Group>
            <Form.Label>Current Satellite Status</Form.Label>
            <TextField
                className="mb-2"
                minRows={1}
                style={{ width: "100%" }}
                onChange={handleValue}
                name="value"
                select
                multiline
                required
            >
                <MenuItem value="Operational">Operational</MenuItem>
                <MenuItem value="Nonoperational">Nonoperational</MenuItem>
                <MenuItem value="Decayed">Decayed</MenuItem>
                <MenuItem value="Graveyard">Graveyard</MenuItem>
                <MenuItem value="Debris">Debris</MenuItem>
                <MenuItem value="Rocket Body">Rocket Body</MenuItem>
            </TextField>
        </Form.Group>
    )
}

export const dateForm = (handleValue) => {
    return(
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en"
        >
            <Form.Label>Date and Time</Form.Label> <br></br>
            <DateTimePicker
                name="value"
                label="YYYY-MM-DD HH:mm:ss (UTC)"
                timezone="UTC"
                className="mb-2"
                onChange={handleValue}
                disableFuture
                format="YYYY-MM-DD HH:mm:ss"
                required
                ampm={false}
            />
        </LocalizationProvider>
    )
}