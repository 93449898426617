import React, {useState, useEffect} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Modal, Container, Form, Row, Col, Button} from "react-bootstrap";
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import "dayjs/locale/en"
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TextField } from "@mui/material";
import { useUser} from "../contexts/user";
import { MsgTypes } from "../utils/Websockets";
import '../css/poi.css';

const ModalAddPoi = ({
    show,
    setShow,
    sat,
}) => {
    const { ws, username, newMsg} = useUser();
    const [timestamp, setTimestamp] = useState(null);
    const [error, setError] = useState(false);

    dayjs.extend(utc);

    useEffect(()=>{
        if(!newMsg)return;
        if (newMsg.id === MsgTypes.SavePOI && newMsg.data.addedRows === 1){
            setShow(false);
        }
    },[newMsg, setShow]);

    // Create a theme instance
    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

    function toMysqlFormat(date) {
        return date.toISOString().slice(0, 19).replace('T', ' ');
    }

    const handleClose = () => {
        setShow(false);
    };


    const handleSubmit = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const poiVal = data.get("value");
        const source = data.get("source");
        if (!timestamp) {
            setError(true);
            return;
        }
        setError(false);

        if(timestamp && poiVal){
            const timestampString = toMysqlFormat(timestamp);
            ws.savePOI(
                sat?.SatNo,
                username,
                poiVal,
                timestampString,
                source
            );
        }
    }
    return (
        <ThemeProvider theme={darkTheme}>
            <Modal
                className="poi-modal"
                show={show}
                onHide={handleClose}
                fullscreen={true}
            >
                <Modal.Header closeButton >
                    <Modal.Title>
                        <LiaMapMarkerAltSolid /> Create POI
                    </Modal.Title>
                </Modal.Header>
                
                <Modal.Body style={{padding:0, overflow:"hidden"}}>
                    <Container>
                        <Row className="pt-4">
                            <Col>
                                <Form id="poiForm" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="poi">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en"
                                        >
                                            <DateTimePicker
                                                name="timestamp"
                                                color="warning"
                                                label="Timestamp"
                                                timezone="UTC"
                                                className="mb-2"
                                                onChange={(newValue) => setTimestamp(newValue)}
                                                disableFuture
                                                format="YYYY-MM-DD HH:mm:ss"
                                                required
                                                ampm={false}
                                            />
                                            {error && <div className="pb-3" style={{ color: 'red' }}>Timestamp is required</div>} 
                                        </LocalizationProvider>
                                        <TextField
                                            className="mb-2"
                                            style={{width:"100%"}}
                                            label="Source URL"
                                            name="source"
                                            type="url"
                                            placeholder="URL of where you found this information"
                                            required
                                        />
                                        
                                        <TextField 
                                            className="mb-2"
                                            minRows={3}
                                            style={{width:"100%"}}
                                            name="value"
                                            label="Value" 
                                            placeholder="POI details, e.g Spacecraft maneuvered with +i2m/s dV."
                                            required
                                        />

                                        <div className="mb-2">
                                            Verified by: <b>{username}</b> 
                                        </div>
                                        <br/>
                                        <Button
                                            type="submit"
                                            variant="warning"
                                            style={{
                                                width:"150px"
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Group>

                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
};
export default ModalAddPoi;
