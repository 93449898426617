import imgPlaceholder from "../assets/images/sat_placeholder.jpg";
import { REGIMES } from "./constants";

export const isGeo = (sat) => {
    return (  sat?.RegimeInt === 256
        || sat?.RegimeInt === 512
        || sat?.RegimeInt === 8 );
};

/**
 * Converts the NPS sat info to a sat summary object.
 * @param {Object} npsSat, the NPS sat info 
 * @returns {Object} the sat summary object
 */
const npsToSatSummary = (npsSat) => {
    return {
        Name: npsSat.Name,
        RegimeInt: npsSat.Regime,
        Regime: REGIMES[npsSat.Regime],
        Raan: parseFloat(npsSat.Raan).toFixed(4),
        Longitude: parseFloat(npsSat.Longitude).toFixed(4),
        LonDriftDegreesPerDay: parseFloat(npsSat.LonDriftDegreesPerDay).toFixed(4),
        Inclination: parseFloat(npsSat.Inclination).toFixed(4),
        Epoch: npsSat.Epoch,
        CountryId: npsSat.CountryId,
        HrrRank: npsSat.HrrRank,
        ObjectType: npsSat.ObjectType,
        LaunchDate: npsSat.LaunchDate,
        SatNo: npsSat.SatNo,
        Data: [],
        // define schemas with defaults
        getDescription: (satData) => {
            const scheme = getScheme(satData, ["overall_summary","details_description"]);
            if (scheme?.[0].Value === "N/A") return "No Description Found.";
            return scheme[0].Value;
        },
        getStatus: (satData) => {
            const scheme = getScheme(satData, ["status_description"]);
            if (scheme?.[0].Value === "N/A") return "Unknown";
            return scheme[0].Value;
        },
        getImages: (satData) => {
            const scheme = getScheme(satData, ["image"]);
            if (scheme?.[0].Value === "N/A") return [imgPlaceholder];
            return scheme?.map(x=> x.Value);
        },
    };
}

/**
 * Converts the NPS schema to appropriate schema for Probe.
 * @param {Object} npsRow, the NPS schema
 * @returns {Object} the schema for Probe
 */
const npsToScheme = (npsRow) => {
    return {
        Id: npsRow.Id,
        Scheme: npsRow.Scheme,
        Value: npsRow.Value,
        Timestamp: npsRow.Timestamp,
        Units: npsRow.Units,
        Source: npsRow.Source,
        Verified: npsRow.Verified,
        VerifiedBy: npsRow.VerifiedBy,
        Validated: npsRow.Validated,
        ValidatedSources: npsRow.ValidatedSources,
        UpdatedAt: npsRow.UpdatedAt,
    };
}

const updateDataArray = (data, existingData, toDelete = false) => {
    // check if data already exists
    const existingDataIndex = existingData.findIndex(x => x.Id === data.Id);
    if (!toDelete && existingDataIndex === -1) {
        // if data doesn't exist, add it
        return [...existingData, data];
    }
    // if data exists, replace it or delete it
    const newData = [...existingData];
    if (toDelete) {
        newData.splice(existingDataIndex, 1);
    } else {
        newData[existingDataIndex] = data;
    }
    return newData;
}

/**
 * Update/Add satellites and satellite data to the existing satellites object. 
 * @param {Array} npsSatData, NPS satellite data
 * @param {Object} satellites, the existing satellites object
 * @param {Boolean} delete, if true, will delete the rows from the satellite data
 * @returns {Object} the new satellites object
 */
export const updateSatellites = (npsSatData, satellites, toDelete = false) => {
    const newSatellites = {...satellites};
    npsSatData.forEach((npsRow) => {
        if (!newSatellites[npsRow.SatNo]) {
            newSatellites[npsRow.SatNo] = npsToSatSummary(npsRow);
        }
        newSatellites[npsRow.SatNo].Data = updateDataArray(
            npsToScheme(npsRow),
            newSatellites[npsRow.SatNo].Data,
            toDelete
        );
    });
    return newSatellites;
};

export const timeDifference = (pastDateStr) => {
    const pastDate = new Date(pastDateStr);
    const currentDate = new Date();

    let yearsDifference = currentDate.getFullYear() - pastDate.getFullYear();
    let monthsDifference = currentDate.getMonth() - pastDate.getMonth();
    let daysDifference = currentDate.getDate() - pastDate.getDate();

    if (daysDifference < 0) {
        monthsDifference--;
        const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        daysDifference += previousMonth.getDate();
    }

    if (monthsDifference < 0) {
        yearsDifference--;
        monthsDifference += 12;
    }

    let result = '';
    if (yearsDifference > 0) {
        result += `${yearsDifference}yr `;
    }
    if (monthsDifference > 0) {
        result += `${monthsDifference}mo `;
    }
    if (daysDifference > 0) {
        result += `${daysDifference}d `;
    }

    return result ? result + 'ago' : 'Today';
}

export const truncateText = (text, maxLength) => {
    if(!text){return "";}
    return text.length > maxLength ? text.substring(0, maxLength) + ".." : text;
};

/**
 * Returns the URL for the flag of the given country id.
 * @param {Object} sat, the satellite object 
 * @returns {String} the URL for the flag of the given country id. If no flag found, returns empty string
 */
export const getFlagLink = (sat) => {
    return (sat?.CountryId 
        ? "http://purecatamphetamine.github.io/country-flag-icons/3x2/"+ sat?.CountryId+".svg"
        : "");
};

export const isLoggedIn = (username) => username && username !== "Guest";

/**
 * Will search the given satellite schema to find the matching scheme data row.
 * @param {Object} rows, The schema rows of sat?.Data
 * @param {Array<string>} schemesArray, An array of schemes you want to search for. 
 * Note, if the first scheme can't be found, it will move on to the second and so on,
 * until data is found. Returns null if no data found
 * @returns {Array} The data row that matches the scheme. If no data found, returns [{Value: "N/A"}]
 */
export const getScheme = (rows, schemeArray) => {
    for (let i=0; i<schemeArray.length; i++) {
        const dataRows = rows.filter(x => x.Scheme === schemeArray[i].trim());
        if (dataRows.length > 0) {
           return dataRows;
        }
    };
    return [{Value: "N/A"}]
}
