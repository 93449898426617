import React, { useEffect, useState } from "react";
import { Button, Modal, Accordion, Row, Col, Figure, FigureImage, Image, Dropdown, ButtonGroup, useAccordionButton, Card } from "react-bootstrap";
import '../css/sat-modal.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useUser } from "../contexts/user";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import ModalAddSchema from "./ModalAddSchema";
import ModalAddSource from "./ModalAddSource";
import { MsgTypes } from "../utils/Websockets";
import { getScheme } from '../utils/Utils';
import { sortBy } from "lodash";
import { LuChevronUp, LuPlus, LuTrash2 } from "react-icons/lu";
import { Link } from "react-router-dom";

let timeoutId;
const ignoreSchemas = [
    "last_updated_at",
    "last_updated_by",
    "nominated_at",
    "nominated_by",
]
const ignoreSource = [
    "turion",
]

function AccordionToggle({ children, eventKey, style, classname }) {

    const customOnClick = useAccordionButton(eventKey);

    return (
        <button
            className={classname}
            style={style}
            onClick={customOnClick}
        >
            {children}
        </button>
    );
}

function ModalEditData({
    show,
    setShow,
    showFooter,
    selectedSat,
}) {
    const { ws, newMsg } = useUser();
    const [showAddSchemaModal, setShowAddSchemaModal] = useState(false);
    const [showAddSourceModal, setShowAddSourceModal] = useState(false);
    const [selectedScheme, setSelectedScheme] = useState({});
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [schema, setSchema] = useState([]);
    const [delSchema, setDelSchema] = useState([]);
    const [deleteId, setDeleteId] = useState("");
    const [expandedItem, setExpandedItem] = useState("0");
    const [readonly, setReadonly] = useState(true);
    const [currScheme, setCurrScheme] = useState("");

    const sortSchema = (unsortSchema) => {
        return sortBy(unsortSchema, Date.parse(unsortSchema.UpdatedAt)).reverse();
    }

    const handleClose = () => setShow(false);

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this schema?\n\nNote: refresh may needed for changes to appear on dosier page.")) {
            setDeleteId(id);
            ws.DeleteSatellite({ Id: id });

            // Immediately update the local state
            setDelSchema(del => { return [...del, id] });

            setUpdateSuccess(
                <span style={{ color: "#ffc107" }}>
                    <CiCircleCheck style={{ fontSize: "1.5rem" }} /> Deleting...
                </span>
            );

            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setUpdateSuccess(<></>), 2000);
        }
    };

    const handleAddSchema = (newSchema) => {
        setSelectedScheme(newSchema);
    };

    const handleSetScheme = (scheme) => {
        setCurrScheme("");
        setSelectedScheme(scheme);
    }

    useEffect(() => {
        if (!newMsg) return;
        if (newMsg.id === MsgTypes.DeleteSatellite) {
            if (newMsg.data.affectedRows === 1) {
                setUpdateSuccess(
                    <span style={{ color: "#ffc107" }}>
                        <CiCircleCheck style={{ fontSize: "1.5rem" }} /> Deleted
                    </span>
                );
                setSchema(schem => schem.filter(schema => schema.Id !== newMsg.data.deletedId));
            } else {
                setUpdateSuccess(
                    <span style={{ color: "red" }}>
                        <CiCircleRemove style={{ fontSize: "1.5rem" }} /> Error deleting, please try again.
                    </span>
                );
            }
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setUpdateSuccess(<></>), 2000);
        }
    }, [newMsg]);

    useEffect(() => {
        if (!selectedSat?.Data) return;
        const filteredData = selectedSat.Data.filter((x) => {
            return !ignoreSchemas.includes(x.Scheme)
                && !delSchema.includes(x.Id);
        });
        setSchema(filteredData);
    }, [selectedSat, delSchema, selectedScheme]);

    const handlePersistAccordion = (e) => {
        setExpandedItem(e);
    }


    const renderAccordionItems = (sectionData, valueDisplayFilter = (element) => { return element.Value }) => {
        return (sectionData[0] && sectionData[0].Value !== "N/A" &&
            (<Card>
                <Card.Header className="border-0 py-4">
                    <Row
                        className="align-items-center align-text-center"
                        style={{
                            fontWeight: 700
                        }}
                    >
                        <Col style={{ minWidth: "50%" }}>
                            <Row className="align-items-center align-text-center">
                                <Col style={{ minWidth: "150px" }} className="py-2">{sectionData[0].Scheme.replaceAll("_", "\u200b_")}</Col>
                                <Col style={{ minWidth: "80%" }}>
                                    <div style={{ maxWidth: "100%" }} className="py-2">{sectionData[0].Value
                                        ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowY: "auto", maxHeight: "110px" }}>{valueDisplayFilter(sectionData[0])}</div>
                                        : ""}
                                    </div>
                                    <div style={{ display: "inline-flex", textWrap: "nowrap", maxWidth: "100%" }} className="py-2"><div className="py-1 pr-1">Source:&nbsp;</div>
                                        <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto" }}>{sectionData[0].Source ? <Link to={sectionData[0].Source}>{sectionData[0].Source}</Link> : <span style={{color:"red"}}>Missing Source</span>}</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ minWidth: "175px" }}>
                            <Row className="align-items-center align-text-center">
                                <Col style={{ minWidth: "55%", textWrap: "nowrap" }}>
                                        <Row>
                                                <Col style={{ minWidth: "50%", textWrap: "nowrap"  }} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>Updated</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{sectionData[0].UpdatedAt}</Col></Row></Col>
                                                <Col style={{ minWidth: "175px", textWrap: "nowrap"}} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>by User</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{sectionData[0].VerifiedBy}</Col></Row></Col>
                                        </Row>
                                </Col>
                                <Col style={{ textAlign: "center", maxWidth: "fit-content"}} className="px-4">
                                    <Dropdown as={ButtonGroup} onClick={() => (handleSetScheme(sectionData[0]))} align="end" >
                                        <Button
                                            style={{ textWrap: "nowrap" }}
                                            variant="warning"
                                            onClick={() => { setReadonly(false); setShowAddSourceModal(!showAddSourceModal); }}
                                        >
                                            <LuPlus />&nbsp;New
                                        </Button>

                                        <Dropdown.Toggle split variant="outline-secondary" className="border-secondary" />
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => { setReadonly(true); setShowAddSourceModal(!showAddSourceModal); }}>
                                                <div style={{ textAlign: "center" }}><LuPlus /> Source</div>
                                            </Dropdown.Item>
                                            <button className="dropdown-delete"
                                                onClick={() => handleDelete(sectionData[0].Id)}>
                                                <LuTrash2 /> Delete
                                            </button>
                                            <Dropdown.Divider />
                                            <AccordionToggle classname="dropdown-item-toggle" style={{ padding: "4px 16px" }} eventKey={sectionData[0].Scheme}>
                                                Sources &&nbsp;History
                                            </AccordionToggle>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {deleteId === sectionData[0].Id ? updateSuccess : ""}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion.Collapse eventKey={sectionData[0].Scheme}>
                    <Card.Body>
                        {/* Data Body */}
                        <Row><AccordionToggle classname="acc-item-toggle rounded" style={{ padding: "4px" }} eventKey={sectionData[0].Scheme}><LuChevronUp fontSize="24px" /></AccordionToggle></Row>
                        <Row>
                            {/*Current sources*/}
                            <Col style={{ minWidth: "50%"}}>
                                <Row className="border-3 mb-2 p-0"
                                    style={{
                                        fontWeight: 700
                                    }}><div className="my-2" style={{
                                        width: "100%",
                                        textAlign: "center",
                                        textDecoration: "underline"
                                    }}>Sources</div></Row>
                                <div style={{ maxHeight: "66vh", overflowY: "auto", overflowX:"hidden"}}>
                                    {sectionData.map((element) => (
                                        <Row key={element.Id}>
                                            {element.Value === sectionData[0].Value &&
                                                <Col className="mb-3" style={{minWidth:"175px"}}>
                                                    <Row className="mb-2 align-items-center">
                                                        <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Source</Col>
                                                        <Col style={{ minWidth: "90%" }}>
                                                            <div className="border rounded p-1" style={{ textWrap: "nowrap", overflowX: "auto", maxWidth: "100%" }}><Link to={element.source}>{element.Source ?? ""}</Link></div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2 align-items-center">
                                                        <Col style={{ minWidth: "70%" }}>
                                                            <Row>
                                                                <Col style={{ minWidth: "50%" }}>
                                                                    <Row>
                                                                        <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Created</Col>
                                                                        <Col style={{ minWidth: "60%", textWrap: "nowrap" }}>{element.UpdatedAt}</Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row>
                                                                        <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>by&nbsp;User</Col>
                                                                        <Col style={{ minWidth: "60%", textWrap: "nowrap" }}>{element.VerifiedBy}</Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col style={{ textAlign: "right" }}>
                                                            <Button
                                                                className="px-4"
                                                                variant="danger"
                                                                onClick={() => handleDelete(element.Id)}
                                                            >
                                                                <LuTrash2 />
                                                            </Button>
                                                            {deleteId === element.Id ? updateSuccess : ""}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                    ))}
                                </div>
                            </Col>
                            {/*Past Entries*/}
                            <Col style={{ minWidth: "175px" }}>
                                <Row className="mb-2 align-items-center"
                                    style={{
                                        fontWeight: 700
                                    }}><div className="my-2" style={{
                                        width: "100%",
                                        textAlign: "center",
                                        textDecoration: "underline"
                                    }}>History</div></Row>
                                <div style={{ maxHeight: "66vh", overflowY: "auto", overflowX: "hidden" }}>
                                    {sectionData.map((element) => (
                                        <Row key={element.Id + "Right"} className="mb-3">
                                            <Col>
                                                <Row className="mb-2 align-items-center">
                                                    <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Value</Col>
                                                    <Col style={{ minWidth: "90%" }}>
                                                        <div className="border rounded p-1" style={{ maxHeight: "55px", overflowY: "auto", overflowX: "hidden" }}>{element.Value ?? ""}</div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center">
                                                    <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Source</Col>
                                                    <Col style={{ minWidth: "90%" }}>
                                                        <div className="border rounded p-1 mb-2" style={{ textWrap: "nowrap", overflowX: "auto" }}><Link to={element.source}>{element.Source ?? ""}</Link></div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2 align-items-center">
                                                    <Col style={{ minWidth: "70%" }}>
                                                        <Row>
                                                            <Col style={{ minWidth: "50%" }}>
                                                                <Row>
                                                                    <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Created</Col>
                                                                    <Col style={{ minWidth: "60%", textWrap: "nowrap" }}>{element.UpdatedAt}</Col>
                                                                </Row>
                                                            </Col>
                                                            <Col>
                                                                <Row>
                                                                    <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>by&nbsp;User</Col>
                                                                    <Col style={{ minWidth: "60%", textWrap: "nowrap" }}>{element.VerifiedBy}</Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }}>
                                                        <Button
                                                            className="px-4"
                                                            variant="danger"
                                                            onClick={() => handleDelete(element.Id)}
                                                        >
                                                            <LuTrash2 />
                                                        </Button>
                                                        {deleteId === element.Id ? updateSuccess : ""}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>)
        )
    }

    return (
        selectedSat && <>
            <ModalAddSchema
                sat={selectedSat}
                show={showAddSchemaModal}
                setShow={setShowAddSchemaModal}
                onAddSchema={handleAddSchema}
            />
            <ModalAddSource
                sat={selectedSat}
                show={showAddSourceModal}
                setShow={setShowAddSourceModal}
                scheme={currScheme}
                schemeData={selectedScheme}
                onAddSchema={handleAddSchema}
                readonly={readonly}
            />
            <Modal
                className="sat-modal"
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        style={{
                            fontSize: "24px"
                        }}>
                        Editing <b>{selectedSat?.Name} - ({selectedSat?.SatNo})</b>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="text-center mb-4 pb-3">
                        <Col sm='3'></Col>
                        <Col>
                            <Carousel
                                showThumbs={false}
                            >
                                {(selectedSat?.getImages(selectedSat?.Data) &&
                                    selectedSat?.getImages(selectedSat?.Data).reverse().map((url) => (
                                        <Figure className="m-0" key={url}>
                                            <FigureImage
                                                key={url}
                                                style={{
                                                    height: "100%",
                                                    width: "auto",
                                                    maxHeight: "350px",
                                                    padding: 'auto',
                                                }}
                                                src={url} rounded
                                            />
                                        </Figure>
                                    ))
                                )}
                            </Carousel>
                        </Col>
                        <Col sm='3'></Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Button
                                variant="warning"
                                onClick={() => setShowAddSchemaModal(!showAddSchemaModal)}
                            > <LuPlus /> Attach Schema</Button>
                        </Col>
                    </Row>

                    {/* Data Start */}
                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Images</Col>
                        <Col className="float-end text-end">
                            <Button
                                style={{ textWrap: "nowrap" }}
                                variant="warning"
                                onClick={() => { setReadonly(false); setCurrScheme("image"); setShowAddSourceModal(!showAddSourceModal); }}
                            >
                                <LuPlus /> New Image
                            </Button>
                        </Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {getScheme(schema, ["image"])[0].Value !== "N/A" ? sortSchema(getScheme(schema, ["image"])).map((img) =>
                            <Card
                                key={img.Id + img.Value}
                            >
                                <Card.Header className="border-0 py-4">
                                    <Row
                                        className="align-items-center align-text-center"
                                        style={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        <Col style={{ minWidth: "50%" }}>
                                            <Row className="align-items-center align-text-center">
                                                <Col style={{ minWidth: "150px" }} className="py-2">{img.Scheme}</Col>
                                                <Col style={{ minWidth: "80%" }}>
                                                    <div style={{ maxWidth: "100%", textWrap: "nowrap" }} className="py-2">{img.Value
                                                        ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto"}}>
                                                            <Link to={img.Value}>{img.Value}</Link></div>
                                                        : ""}
                                                    </div>
                                                    <div style={{ maxWidth: "100%", display: "inline-flex", textWrap: "nowrap" }} className="py-2"><div className="py-1 pr-1">Source:&nbsp;</div>
                                                        {ignoreSource.findIndex(s => s.toLowerCase() === img.Source) === -1
                                                            ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto" }}>
                                                                {img.Source ? <Link to={img.Source}>{img.Source}</Link> : <span style={{color:"red"}}>Missing Source</span>}</div>
                                                            : <div className="p-1" style={{ fontWeight: 500, overflowX: "auto" }}>{img.Source}</div>}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col style={{ minWidth: "175px" }}>
                                            <Row className="align-items-center align-text-center">
                                                <Col style={{ minWidth: "55%", textWrap: "nowrap" }}>
                                                    <Row>
                                                        <Col style={{ minWidth: "50%", textWrap: "nowrap"  }} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>Updated</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{(img.UpdatedAt)}</Col></Row></Col>
                                                        <Col style={{ minWidth: "175px", textWrap: "nowrap"}} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>by User</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{img.VerifiedBy}</Col></Row></Col>
                                                    </Row>
                                                </Col>
                                                <Col style={{ textAlign: "center", maxWidth: "fit-content"}} className="px-4">
                                                    <Dropdown as={ButtonGroup} onClick={() => (handleSetScheme(img))} align="end">
                                                        <AccordionToggle classname="btn btn-warning dropdown-item-toggle" eventKey={img.Value}>
                                                            Preview
                                                        </AccordionToggle>
                                                        <Dropdown.Toggle split variant="outline-secondary"/>
                                                        <Dropdown.Menu>
                                                            <button className="dropdown-delete"
                                                                disabled={ignoreSource.findIndex(s => s === img.Source) !== -1}
                                                                onClick={() => handleDelete(img.Id)}>
                                                                <LuTrash2 /> Delete
                                                            </button>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {deleteId === img.Id ? updateSuccess : ""}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Accordion.Collapse eventKey={img.Value}>
                                    <Card.Body>
                                        <Row className="mb-3"><AccordionToggle classname="acc-item-toggle rounded" style={{ padding: "4px" }} eventKey={img.Value}><LuChevronUp fontSize="24px" /></AccordionToggle></Row>
                                        <Row>
                                            <Image src={img.Value}
                                                style={{
                                                    maxHeight: "300px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )
                            : <Card><Card.Header className="border-bottom-0">Placeholder Image</Card.Header></Card>}

                    </Accordion>
                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Summary</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["overall_summary"])))}

                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Launch Details</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["launch_summary"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["launch_date"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["launch_vehicle"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["launch_mass"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["launch_profile"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["interim_orbits"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["separation_waypoint"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["deployment_waypoint"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["itu_slot_projected"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["launch_history_for_similar"])))}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Mission Details</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["details_description"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["owner"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["operator"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["payload_manufacturer"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["name"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["is_military"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["itu_name"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["primary_mission"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["secondary_mission"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["assoc_sats"])),
                            (element) => { return element.Value.indexOf(" ") === -1 ? element.Value.replaceAll(",", ", ") : element.value })}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Characteristics</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["payload"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["bus"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["size"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["propulsion"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["rcs_body_dimension"])))}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Frequencies & Comms</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["comms_description"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["freq_band"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["frequency"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["ttc_uplink_freqs"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["ttc_downlink_freqs"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["ttc_crosslink_freqs"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["polarization"])))}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Satellite Status</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["status_description"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["estimated_mission_capacity"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["est_remaining_fuel"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["est_customers_supported"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["est_current_mass"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["deviation_from_mission"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["observed_foreign_mil_ties"])))}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["possible_anomalies"])))}
                    </Accordion>

                </Modal.Body>
                {showFooter ? (
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            className="decline-delete-button"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                ) : (<></>)}

            </Modal>
        </>
    );
}

export default ModalEditData;
