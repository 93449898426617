import React, {useState, useEffect} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Modal, Container, Form, Row, Col, Button} from "react-bootstrap";
import { TextField } from "@mui/material";
import { useUser} from "../contexts/user";
import Autocomplete from '@mui/material/Autocomplete';
import '../css/poi.css';
import { MsgTypes } from "../utils/Websockets";
import { EDITABLE_SCHEMAS } from "../utils/constants";
import * as AddSchemaForm from "../utils/AddSchemaForms";

const ModalAddSchema = ({
    show,
    setShow,
    sat,
    onAddSchema
}) => {
    const { ws, username, newMsg } = useUser();
    const [selectedScheme, setSelectedScheme] = useState(EDITABLE_SCHEMAS[0]);
    const [saving, setSaving] = useState(false);

    const genericDetails = ["separation_waypoint", 
        "estimated_mission_capacity", 
        "deviation_from_mission", 
        "possible_anomalies", 
        "itu_slot_projected", 
        "observed_foreign_mil_ties",
        "nomination_justification",
        "est_customers_supported", 
        "launch_id"];

    const handleChange = (_, newValue) => {
        setSelectedScheme(newValue);
    };
    // Handles input value for textfield props
    const [thisValue, setThisValue] = useState("");
    const handleValue = (e) => {
        setThisValue(e);
    };
    
    useEffect(()=>{
        if(!newMsg)return;
        if (newMsg.id === MsgTypes.SaveScheme){
            setSaving(false);
            if(newMsg.data.addedRows === 1){
                setShow(false);
            }
        }
    },[newMsg, setShow]);

    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

    const handleClose = () => {
        setShow(false);
    };


    const handleSubmit = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const source = data.get("source");
        const value = data.get("value");
        if(!selectedScheme || !source || !value){
            return;
        }
        setSaving(true);
        ws.saveScheme(sat?.SatNo, username, selectedScheme, source, value);

        const newSchema = {
            Id: Date.now(),
            Scheme: selectedScheme,
            Source: source,
            Value: JSON.stringify(thisValue),
            VerifiedBy: username,
            UpdatedAt: new Date().toISOString()
        };
        onAddSchema(newSchema);
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Modal
                className="addSchemaModal"
                show={show}
                onHide={handleClose}
                style={{ background: "#000000c7" }}
            >
                <Modal.Header closeButton >
                    <Modal.Title>
                        Add Schema
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{padding:0, overflow:"hidden"}}>
                    <Container>
                        <Row style={{color:"red", paddingLeft:10, paddingTop:10}}>**Be aware of required units and data types</Row>
                        <Row className="pt-4">
                            <Col>
                                <Form id="poiForm" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="poi">  
                                        <Form.Label>Schema</Form.Label>
                                        <Autocomplete
                                            className="mb-2"
                                            ListboxProps={{ style: { maxHeight: '18rem' } }}
                                            disablePortal
                                            id="schemaCombo"
                                            options={EDITABLE_SCHEMAS}
                                            value={selectedScheme}
                                            onChange={handleChange}
                                            required
                                            renderInput={(params) => <TextField name="scheme" {...params} />}
                                        />

                                        <Form.Label>Sources</Form.Label>
                                        <TextField
                                            className="mb-2"
                                            style={{width:"100%"}}
                                            name="source"
                                            type="url"
                                            placeholder="URL(s) of where you found this information. List in CSV format."
                                            multiline
                                            required
                                        />

                                        <br></br>
                                        {/* IS MILITARY */}
                                        {(selectedScheme === "is_military") &&(
                                            AddSchemaForm.isMilitaryForm(handleValue)
                                        )}

                                        {/* STATUS DESCRIPTION */}
                                        {(selectedScheme === "status_description") &&(
                                            AddSchemaForm.statusForm(handleValue)
                                        )}

                                        {/* LAUNCH DATE */}
                                        {selectedScheme === "launch_date" &&(
                                            AddSchemaForm.dateForm(handleValue)
                                        )}
                                        {/* BUS */}
                                        {(selectedScheme === "bus" || selectedScheme === "payload") &&(
                                            AddSchemaForm.textForm("Bus Name","Name (and additional comments if applicable",2)
                                        )}

                                        {/* EXPECTED LIFE */}
                                        {selectedScheme === "expected_life" &&(
                                            AddSchemaForm.textForm("Expected Life","Expected Life [Units: yrs (Ex. 3 yrs)]",1)
                                        )}

                                        {/* COMMS DESCRIPTION */}
                                        {selectedScheme === "comms_description" &&(
                                            AddSchemaForm.textForm("Frequencies & Comms Description", "Summary of Communications", 3)
                                        )}

                                        {/* DETAILS DESCRIPTION */}
                                        {selectedScheme === "details_description" &&(
                                            AddSchemaForm.textForm("Launch Details Description", "Summary of Launch Details", 3)
                                        )}

                                        {/* FREQ BANDS */}
                                        {(selectedScheme === "freq_band" || selectedScheme === "frequency")&&(
                                            AddSchemaForm.textForm("Frequency", "Frequency Band/Frequency [Units: Hz]", 1)
                                        )}

                                        {/* ITU NAME */}
                                        {selectedScheme === "itu_name" &&(
                                            AddSchemaForm.textForm("International Code", "Intl Code/Intl Designator/NSSDCA/COSPAR ID [XXXX-XXXA]", 2)
                                        )}

                                        {/* LAUNCH MASS */}
                                        {selectedScheme === "launch_mass" &&(
                                            AddSchemaForm.textForm("Launch Mass", "Launch Mass of Satellite [Units: Kg]", 1)
                                        )}

                                        {/* LAUNCH VEHICLE */}
                                        {selectedScheme === "launch_vehicle" &&(
                                            AddSchemaForm.textForm("Launch Vehicle Name", "Launch vehicle's full name (ex. Long March XX)", 1)
                                        )}

                                        {/* NAME */}
                                        {selectedScheme === "name" &&(
                                            AddSchemaForm.textForm("Alias/Name", "Enter additional names. These will show up as aliases. Use format: <name>, <name>.",2)
                                        )}

                                        {/* OPERATOR */}
                                        {(selectedScheme === "operator" || selectedScheme === "owner")&&(
                                            AddSchemaForm.textForm("Owner/Operator", "Enter full name (ex. Ministry of Defense)", 2)
                                        )}

                                        {/* OVERALL SUMMARY */}
                                        {selectedScheme === "overall_summary" &&(
                                            AddSchemaForm.textForm("Overall Summary", "Enter brief description about satellite and additional relevant comments", 3)
                                        )}

                                        {/* PAYLOAD MANUFACTURER */}
                                        {selectedScheme === "payload_manufacturer" &&(
                                            AddSchemaForm.textForm("Payload Manufacturer", "Enter full name of payload manufacturer", 2)
                                        )}

                                        {/* POLARIZATION */}
                                        {selectedScheme === "polarization" &&(
                                            AddSchemaForm.textForm("Polarization", "Enter polarization [Units: C/m^2]", 1)
                                        )}

                                        {/* PRIMARY MISSION */}
                                        {(selectedScheme === "primary_mission" || selectedScheme === "secondary_mission") &&(
                                            AddSchemaForm.textForm("Mission Purpose", "Enter mission purpose/goal (only a few words)", 2)
                                        )}

                                        {/* PROPULSION */}
                                        {selectedScheme === "propulsion" &&(
                                            AddSchemaForm.textForm("Propulsion", "Enter propulsion type", 1)
                                        )}

                                        {/* RCS BODY DIMENSION */}
                                        {selectedScheme === "rcs_body_dimension" &&(
                                            AddSchemaForm.textForm("Radar Cross Section Dimension", "Enter RCS [Units: m^2]", 1)
                                        )}

                                        {/* SIZE */}
                                        {selectedScheme === "size" &&(
                                            AddSchemaForm.textForm("Size", "Enter size (length: , width: , height: ) [Units: m^2]", 1)
                                        )}

                                        {/* TTC DOWNLINK FREQS or UPLINK FREQS */}
                                        {(selectedScheme === "ttc_downlink_freqs" || selectedScheme === "ttc_uplink_freqs" || selectedScheme === "ttc_crosslink_freqs") &&(
                                            AddSchemaForm.textForm("TTC Frequencies", "Enter corresponding ttc frequency [Units: Hz]", 1)
                                        )}

                                        {/* IMAGE */}
                                        {(selectedScheme === "image") &&(
                                            AddSchemaForm.textForm("Image Link", "Image URL (right click on image from site)", 1)
                                        )}

                                        {/* LAUNCH HISTORY FOR SIMILAR */}
                                        {(selectedScheme === "launch_history_for_similar") &&(
                                            AddSchemaForm.textForm("Launch History for Associated Satellites", "Summary of launch history of associated satellites", 1)
                                        )}

                                        {/* EST REMAINING FUEL */}
                                        {(selectedScheme === "est_remaining_fuel") &&(
                                            AddSchemaForm.textForm("Estimated Remaining Fuel", "Enter remaining fuel [Units: kg]", 1)
                                        )}

                                        {/* LAUNCH SUMMARY */}
                                        {(selectedScheme === "launch_summary") &&(
                                            AddSchemaForm.textForm("Summary", "Summary of launch details", 1)
                                        )}

                                        {/* INTERIM ORBITS */}
                                        {(selectedScheme === "interim_orbits") &&(
                                            AddSchemaForm.textForm("Interim Orbits", "Enter interim orbit(s)", 1)
                                        )}

                                        {/* LAUNCH PROFILE */}
                                        {(selectedScheme === "launch_profile") &&(
                                            AddSchemaForm.textForm("Launch Profile", "Enter data (ex. SSO)", 2)
                                        )}

                                        {/* ASSOC SATS */}
                                        {(selectedScheme === "assoc_sats") &&(
                                            AddSchemaForm.textForm("Associated Satellites", "Enter associated satellites by NORAD Id in CSV format", 2)
                                        )}

                                        {/* EST CURRENT MASS */}
                                        {(selectedScheme === "est_current_mass") &&(
                                            AddSchemaForm.textForm("Current Estimated Mass", "Enter mass [Units: kg]", 1)
                                        )}

                                        {/* LAUNCH SITE ID */}
                                        {(selectedScheme === "launch_site_id") &&(
                                            AddSchemaForm.textForm("Launch Site", "Enter Launch Site (ID and full name)", 1)
                                        )}

                                        {/* GENERIC DETAILS */}
                                        {(genericDetails.includes(selectedScheme)) &&(
                                            AddSchemaForm.textForm("Details", "Enter data", 2)
                                        )}

                                        <div className="pb-2">
                                            <br></br>
                                            Verified by: <b>{username}</b> 
                                        </div>
                                        <br/>
                                        <Button
                                            type="submit"
                                            disabled={saving}
                                            variant="warning"
                                            style={{
                                                width:"150px"
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Group>

                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
};
export default ModalAddSchema;
