import React, { useEffect, useState } from "react";
import { Modal, Container, Form, Row, Col, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useUser } from "../contexts/user";
import { MsgTypes } from "../utils/Websockets";
import { EDITABLE_SCHEMAS } from "../utils/constants";

const ModalAddSatellite = ({ show, setShow }) => {
    const { ws, newMsg, username } = useUser();
    const [SatNo, setSatNo] = useState("");
    const [Value, setValue] = useState("");
    const [Source, setSource] = useState("");

    useEffect(() => {
        if (newMsg && newMsg.id === MsgTypes.CreateSatellite) {
            if (newMsg.success) {
                setShow(false);
                console.log("Success");
            } else {
                console.log("Failed");
            }
        }
    }, [newMsg, setShow]);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const handleClose = () => setShow(false);

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const scheme = EDITABLE_SCHEMAS[9];
        ws.CreateSatellite({ satNo: SatNo, username, scheme, source: Source, value: Value });
        setShow(false);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Satellite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={12}>
                                    <TextField
                                        fullWidth
                                        label="SatNo"
                                        variant="outlined"
                                        value={SatNo}
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value, 10);
                                            if (value > 0 || isNaN(value)) {
                                                setSatNo(e.target.value);
                                            }
                                        }}
                                        required
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={12}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={Value}
                                        onChange={(e) => setValue(e.target.value)}
                                        required
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={12}>
                                    <TextField
                                        fullWidth
                                        label="Source"
                                        variant="outlined"
                                        rows={4}
                                        value={Source}
                                        onChange={(e) => setSource(e.target.value)}
                                        required
                                    />
                                </Col>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
};

export default ModalAddSatellite;