import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { sortBy } from 'lodash';
import { useUser} from "../contexts/user";
//components
import SatelliteCard from '../components/SatelliteCard';
import SpacefanaDashboard from '../components/SpacefanaDashboard';
import OrbitalView from "../components/OrbitalView";
import Poi from '../components/Poi';
import ModalEditData from '../components/ModalEditDataV2.0';
import NotFound from '../components/NotFound';
import OverlayElement from '../components/OverlayElement';
//utils
import { timeDifference, getScheme, isLoggedIn } from '../utils/Utils';
//css
import "../css/OverlayElement.css";
//icons
import { HiOutlineCube } from "react-icons/hi2";
import { CgDetailsMore } from "react-icons/cg";
import { GiLightningFrequency } from "react-icons/gi";
import { MdOutlineSatelliteAlt } from "react-icons/md";
import { FiEdit } from 'react-icons/fi';
import { GiChart } from 'react-icons/gi';
import { LiaRocketSolid } from "react-icons/lia";
import { GiOrbit } from "react-icons/gi";
import { LuChevronUp, LuChevronDown, LuInfo, LuOrbit } from "react-icons/lu";

const Dossier = () => {
    const { username, satellites } = useUser();
    const { id } = useParams();
    const [notFound, setNotFound] = useState(false);
    const [sat, setSat] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSpacefanaModal, setShowSpacefanaModal] = useState(false);
    const [associatedSatellites, setAssociatedSatellites] = useState([]);
    const [showAssocSatList, toggleShowAssocSatList] = useState(false);
    const [showOrbitalViewModal, setShowOrbitalViewModal] = useState(false);

    useEffect(() => {
        if (sat?.Data) {
            const assocSats = sortSchema(getScheme(sat.Data, ["assoc_sats"]))[0].Value;
            if (assocSats !== "N/A") {
                setAssociatedSatellites(assocSats.split(',').map(s => s.trim()));
            } else {
                setAssociatedSatellites([]);
            }
        }
    }, [sat?.Data]);
    
    useEffect(()=>{ 
        if (satellites[id]) {
            setNotFound(false);
            return setSat({...satellites[id]});
        } else if (Object.keys(satellites).length > 0) {
            setNotFound(true);
        }
    },[id, satellites, setSat]);
     
    const toggleEditModal = () => setShowEditModal(!showEditModal);

    const toggleSpacefanaModal = () => {
        setShowSpacefanaModal(!showSpacefanaModal);
    }
    
    const toggleOrbitalViewModal = () => {
        setShowOrbitalViewModal(!showOrbitalViewModal);
    };

    const associatedSatNames = (assocSat) => satellites[parseInt(assocSat)]?.Name ? satellites[parseInt(assocSat)].Name : " ";

    /// Sorts schema by UpdatedAt column
    const sortSchema = (unsortSchema) => {
        return sortBy(unsortSchema,Date.parse(unsortSchema.UpdatedAt)).reverse();
    }

    /// returns ordered html of source links
    const getSource = (sortedSchema) => {
        if (!sortedSchema[0].Source) {
            return sortedSchema[0].Value !== "N/A"
            ?  <>
                    <sup style={{ color:"red" }}>
                        <OverlayElement 
                            text={` [Source] `}
                            element={<div className="ps-1">No source provided.</div>}
                        />
                    </sup>
                </>
            : <></>
        }
        let sourceText = [];
        sortedSchema.forEach(scheme => {if (scheme.Value === sortedSchema[0].Value) {sourceText.push(scheme.Source)}})
        return  <>
                    <sup className='style-source'>
                        <OverlayElement 
                            text={` [Source] ${sourceText.length === 1 ? "" : `(${sourceText.length})`}`} // "[Source]" or "[Source] (<#>)"
                            element={sourceText.map((src, id) => {return <div key={id} className="ps-1"><Link className="style-link" to={src}>{src}</Link><br/></div>})}
                            />
                    </sup>
                </>;
    }

    /// Wrapper to get most recent scheme value and sources.
    const getSourceAndValue = (schema) => {
        const sortedSchema = sortSchema(schema);
        return  <>
                    {sortedSchema[0].Value}{getSource(sortedSchema)}
                </>;
    }

    return (
        <>
            {notFound && (
                <NotFound
                    id={id}
                >
                </NotFound>
            )}
            {sat?.Data && (

                <div style={{padding:"20px"}}>
                    <Helmet>
                        <title>({sat.SatNo}) {sat.Name}</title>
                        <meta name="satellite-name" content={sat ? `Satellite: ${sat.Name}` : "Satellite Details"} />
                        <meta property="description" content={sat ? sat.getDescription(sat.Data) : "No satellite data available"} />
                    </Helmet>
                    <h2>{sat.SatNo} - {sat.Name}</h2>
                    <br></br>
                    <Row>
                        <Col md={8} className="mb-3">

                            {/* Edit Data Button */}
                            {isLoggedIn(username) && (
                                <div className="mb-2" style={{ display: 'flex', gap: '20px' }}>
                                    <Button variant="outline-primary" onClick={toggleEditModal} style={{ borderColor: 'white', color: 'white' }}>
                                        <FiEdit /> Edit Data
                                    </Button>
                                    <Button onClick={toggleSpacefanaModal} variant="outline-primary" style={{ borderColor: 'white', color: 'white' }}>
                                        <GiChart /> Elset History
                                    </Button>
                                    <Button onClick={toggleOrbitalViewModal} variant="outline-primary" style={{ borderColor: 'white', color: 'white' }}>
                                        <LuOrbit /> Orbital View
                                    </Button>
                                </div>
                            )}
                            <span style={{fontStyle: "italic", color:"#ffc107"}}>
                                <LuInfo/> 
                                This is publicly available information and was not provided through Intel channels.
                            </span>
                            {/* Edit Modal Data */}
                            <ModalEditData
                                show={showEditModal}
                                setShow={setShowEditModal}
                                selectedSat={sat}
                            />

                            {/* Summary */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title id="summary" style={{color:"#ffc107"}}>
                                        Summary 
                                    </Card.Title>
                                    {getSourceAndValue(getScheme(sat?.Data, ["overall_summary"]))}
                                </Card.Body>
                            </Card> 

                            {/* Orbit */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{color:"#ffc107"}}><GiOrbit/> Orbit</Card.Title>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Last Seen (18th SPCS)</Col>
                                                <Col>[{timeDifference(sat?.Epoch)}] {sat?.Epoch}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Inclination</Col>
                                                <Col>{sat?.Inclination}</Col>
                                            </Row>
                                            
                                        </Col>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Longitude (°E)</Col>
                                                <Col>{sat?.Longitude}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Longitude Drift (°E/day)</Col>
                                                <Col>{sat?.LonDriftDegreesPerDay}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card> 

                            {/* Launch Details*/}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{color:"#ffc107"}}><LiaRocketSolid/> Launch Details</Card.Title>

                                    <div className="pb-3">
                                        {getScheme(sat?.Data,["launch_summary"])[0].Value === "N/A"
                                        ? ""
                                    : getSourceAndValue(getScheme(sat?.Data, ["launch_summary"]))}
                                    </div>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Launch Date</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["launch_date"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Launch Vehicle</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["launch_vehicle"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Launch Mass</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["launch_mass"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Launch Profile</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["launch_profile"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Interim Orbits</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["interim_orbits"]))}</Col>
                                            </Row>
                                        </Col>

                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Separation Waypoint</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["separation_waypoint"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Deployment Waypoint</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["deployment_waypoint"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Itu Slot Projected</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["itu_slot_projected"]))}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="border-top mt-2 pt-2">
                                        <Col sm="2">Launch History For Similar</Col>
                                        <Col>{getSourceAndValue(getScheme(sat?.Data, ["launch_history_for_similar"]))}</Col>
                                    </Row>
                                </Card.Body>
                            </Card> 

                            {/* Mission Details */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{color:"#ffc107"}}><CgDetailsMore/> Mission Details</Card.Title>
                                    <div className="pb-3">
                                            {getScheme(sat?.Data,["details_description"])[0].Value === "N/A"
                                            ? ""
                                        : getSourceAndValue(getScheme(sat.Data,["details_description"]))}
                                    </div>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Owner</Col>
                                                <Col sm="7">{getSourceAndValue(getScheme(sat?.Data, ["owner"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Operator</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["operator"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Payload Manufacturer</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["payload_manufacturer"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Names</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["name"]))}</Col>
                                            </Row>
                                        </Col>

                                        <Col sm="6">
                                        <Row>
                                                <Col sm="4">Mission Type</Col>
                                                <Col>{sortSchema(getScheme(sat?.Data, ["is_military"]))[0].Value === "1"
                                                    ? "Military"
                                                    : sortSchema(getScheme(sat?.Data, ["is_military"]))[0].Value === "0"
                                                        ? "Civilian"
                                                        : "N/A"}
                                                    {getSource(getScheme(sat?.Data, ["is_military"]))}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">ITU Name</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["itu_name"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Primary Mission</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["primary_mission"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Secondary Mission</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["secondary_mission"]))}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {/* Associated Satellites */}
                                        <Row className="border-top" style={{marginTop: '.6rem', paddingTop:'.7rem'}}>
                                            <Col sm="2">
                                                Associated Sat(s) {getSource(getScheme(sat?.Data, ["assoc_sats"]))}
                                            </Col>
                                                {(associatedSatellites.length >= 1) && (
                                                    <Col sm="4">
                                                        {(!showAssocSatList 
                                                        ? associatedSatellites.slice(0, 3).map((ida) => <div key={ida}><Link className="style-link" href={`/dossier/${ida}`}>{ida}</Link> - {associatedSatNames(ida)}</div>)
                                                        : associatedSatellites.map((ida) => <div key={ida}><Link  className="style-link" href={`/dossier/${ida}`}>{ida}</Link> - {associatedSatNames(ida)}</div>))}
                                                        {(associatedSatellites.length > 3) &&
                                                            <>
                                                                {(!showAssocSatList) && (<div style={{ fontSize: ".75rem", color: 'lightGray' }}>+ {associatedSatellites.length - 3} more...</div>)}
                                                                <Button variant="outline-warning" className='mt-2 btn-outline-warning' onClick={() => toggleShowAssocSatList(!showAssocSatList)}>
                                                                    {showAssocSatList ? <><LuChevronUp/> Collapse</> : <><LuChevronDown/> Expand</>}
                                                                </Button>
                                                            </>
                                                        }
                                                    </Col>
                                                )}
                                            {(associatedSatellites.length < 1) && (
                                                <Col sm="4">N/A</Col>
                                            )}
                                        </Row>
                                </Card.Body>
                            </Card>

                            {/* Characteristics */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{color:"#ffc107"}}><HiOutlineCube/> Characteristics</Card.Title>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Payload</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["payload"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Bus</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["bus"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Size</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["size"]))}</Col>
                                            </Row>
                                            
                                        </Col>

                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Propulsion</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["propulsion"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">RCS/Body Dimension</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["rcs_body_dimension"]))}</Col>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* Frequencies/Comms */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{color:"#ffc107"}}><GiLightningFrequency/> Frequencies & Comms</Card.Title>
                                    <div className="pb-3">
                                        {getScheme(sat?.Data,["comms_description"])[0].Value === "N/A"
                                        ? ""
                                    : getSourceAndValue(getScheme(sat.Data,["comms_description"]))}
                                    </div>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Freq Bands</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["freq_band"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Freq Range</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["frequency"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">TT&C Uplink Freqs</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["ttc_uplink_freqs"]))}</Col>
                                            </Row>
                                        </Col>

                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">TT&C Downlink Freqs</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["ttc_downlink_freqs"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">TT&C Crosslink Freqs</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["ttc_crosslink_freqs"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Polarization</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["polarization"]))}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* Satellite Status*/}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{color:"#ffc107"}}><MdOutlineSatelliteAlt/> Satellite Status</Card.Title>
                                    <div className="pb-3">
                                        {getSourceAndValue(getScheme(sat?.Data,["status_description"]))}
                                    </div>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Est. Mission Capacity</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["estimated_mission_capacity"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Est. Remaining Fuel</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["est_remaining_fuel"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Est. Customers</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["est_customers_supported"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Est. Current Mass</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["est_current_mass"]))}</Col>
                                            </Row>
                                        </Col>

                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Deviation from Mission</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["deviation_from_mission"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Observed Mil Ties</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["observed_foreign_mil_ties"]))}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Possible Anomalies</Col>
                                                <Col>{getSourceAndValue(getScheme(sat?.Data, ["possible_anomalies"]))}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* NEW MODAL FOR ELSET HISTORY */}
                            <Modal
                                show={showSpacefanaModal}
                                onHide={toggleSpacefanaModal}
                                size="xl"
                                aria-labelledby="spacefana-modal"
                                centered
                                dialogClassName="spacefana-modal-dialog"
                            >
                                <Modal.Header closeButton className="spacefana-modal-header">
                                    <h6>({sat?.SatNo}) {sat?.Name}</h6>
                                </Modal.Header>
                                <Modal.Body>
                                    {sat && (
                                        <SpacefanaDashboard
                                            satNumber={sat.SatNo}
                                            useIframe={true}
                                        />
                                    )}
                                </Modal.Body>
                            </Modal>

                            {/* Modal for Orbital View */}
                            <Modal
                                show={showOrbitalViewModal}
                                onHide={toggleOrbitalViewModal}
                                size="xl"
                                aria-labelledby="orbital-view-modal"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="orbital-view-modal">
                                        {sat?.Name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {sat && (
                                        <OrbitalView 
                                            satellites={[sat.SatNo, ...associatedSatellites]} 
                                            useIframe={true}
                                        />
                                    )}
                                </Modal.Body>
                            </Modal>
                        </Col>

                        {/* Right Column */}
                        <Col 
                            md={4} 
                            className="mb-4"
                        >
                            {sat 
                                ?
                                (
                                    <>
                                        <SatelliteCard 
                                            sat={sat} 
                                        />
                                        <Poi
                                            sat={sat}
                                        />
                                    </>
                                ) 
                                :(
                                <></>)}
                        </Col>
                    </Row>
                </div>
            )}
        </>
      );
};

export default Dossier;
