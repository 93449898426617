import React, {useState, useEffect} from "react";
import {Modal, Container, Form, Row, Col, Button, FormControl} from "react-bootstrap";
import { useUser} from "../contexts/user";
import '../css/poi.css';
import { MsgTypes } from "../utils/Websockets";

let savingId;

const ModalAddSource = ({
    show,
    setShow,
    sat,
    scheme = "",
    schemeData,
    readonly = true,
    onAddSchema
}) => {
    const { ws, username, newMsg } = useUser();
    const [saving, setSaving] = useState(false);
    const [saveStatus, setSaveStatus] = useState(<></>)

    useEffect(()=>{
        if(!newMsg)return;
        if (newMsg.id === MsgTypes.SaveScheme){
            clearTimeout(savingId);
            setSaveStatus(<></>);
            setSaving(false);
            if(newMsg.data.addedRows === 1){
                setShow(false);
            }
        }
    },[newMsg, setShow]);

    const handleClose = () => {
        setShow(false);
    };

    const handleSubmit = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const schema = data.get("schema");
        const source = data.get("source");
        const value = data.get("value");
        if(!schema || !source || !value){
            return;
        }

        setSaveStatus(<div style={{color:"lightblue"}}>saving...</div>);
        setSaving(true);
        ws.saveScheme(sat?.SatNo, username, schema, source, value);

        const newSchema = {
            Id: Date.now(),
            Scheme: schema,
            Source: source,
            Value: value,
            VerifiedBy: username,
            UpdatedAt: new Date().toISOString()
        };
        onAddSchema(newSchema);

        savingId = setTimeout(() => {setSaveStatus(<div style={{color:"red"}}>Operation timed out. Try Again...</div>); setSaving(false);}, 10000)
    }

    return (
            <Modal
                className="addSchemaModal"
                show={show}
                onHide={handleClose}
                style={{ background: "#000000c7" }}
            >
                <Modal.Header closeButton >
                    <Modal.Title>
                        {readonly ? "Add Additional Source to Current Value" : "New Value"}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{padding:0, overflow:"hidden"}}>
                    <Container>
                        <Row className="pt-4">
                            <Col>
                                <Form id="poiForm" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="poi">  
                                        <Form.Label>Scheme</Form.Label>
                                        <FormControl
                                            className="mb-2"
                                            name="schema"
                                            style={{boxShadow:"none"}}
                                            defaultValue={scheme.length === 0 ? schemeData.Scheme : scheme}
                                            type="text"
                                            readOnly
                                            required
                                        />

                                        <Form.Label>Source</Form.Label>
                                        <Form.Control
                                            className="mb-2"
                                            style={{backgroundColor:"#181b1f"}}
                                            name="source"
                                            type="url"
                                            placeholder="URL of where you found this information."
                                            required
                                        />

                                        <Form.Label>Value</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            className="mb-2"
                                            style={readonly ? {boxShadow:"none"} : {backgroundColor:"#181b1f"}}
                                            defaultValue={readonly ? schemeData.Value : ""}
                                            name="value"
                                            type="text"
                                            readOnly={readonly}
                                            required
                                        />
                                        <div className="pb-2">
                                            <br></br>
                                            Verified by: <b>{username}</b> 
                                        </div>
                                        <br/>
                                        <Button
                                            type="submit"
                                            disabled={saving}
                                            variant="warning"
                                            style={{
                                                width:"150px"
                                            }}
                                        >
                                            Save
                                        </Button>
                                        {saveStatus}
                                    </Form.Group>

                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
    );
};
export default ModalAddSource;
